@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

.wrapper {
    width: 100%;
    position: relative;
    float: left;
    background-color: #e2e2e2;
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    color: #000;
    overflow: hidden;
    box-sizing: border-box;
}

.clear {
    width: 100%;
    position: relative;
}
