@media screen and (max-width: 640px) {
    .jp h2.footer-title {
        font-size: 1.75rem;
    }
}

@media screen and (max-width: 370px) {
    .jp h2.footer-title {
        font-size: 1.25rem;
    }
}