.characters {
    width: 100%;
    position: relative;
    display: inline-block;
    background-color: #DBFF00;
    padding: 40px 1%;
    box-sizing: border-box;
}

.characters-content {
    width: 100%;
    max-width: 1200px;
    position: relative;
    display: inline-block;
}

.characters-bg {
    width: 25%;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
}

.characters-bg::before {
    content: '';
    display: block;
    padding-top: 100%;
    position: relative;
}

.characters-bg-img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-image: url('../../img/characters-bg.svg');
    background-size: cover;
}

.characters-block {
    width: 100%;
    max-width: 900px;
    position: relative;
    display: inline-block;
    margin: 40px 0;
    font-size: 0;
    line-height: 0;
}

.characters-text-block {
    width: 40%;
    position: relative;
    display: inline-block;
    padding: 0 1%;
    text-align: left;
    vertical-align: middle;
    box-sizing: border-box;
}

h3.characters-title {
    position: relative;
    display: block;
    margin: 0;
    font-weight: 100;
    font-size: 2.5rem;
    line-height: 1.2;
}

p.characters-text {
    position: relative;
    display: block;
    margin-top: 18px;
    font-size: 0.733rem;
    line-height: 1.18181818;
    text-align: justify;
}

.characters-img-block {
    width: 60%;
    position: relative;
    display: inline-block;
    padding: 0 1%;
    vertical-align: middle;
    font-size: 0;
    line-height: 0;
    box-sizing: border-box;
}

.characters-img {
    width: 44%;
    position: relative;
    display: inline-block;
    margin: 0 3%;
    border-bottom: 2px solid #000;
    box-sizing: border-box;
}

.characters-img img {
    width: 100%;
}

@media screen and (max-width: 767px) {
    .characters-text-block, .characters-img-block {
        width: 100%;
    }

    .characters-text-block {
        text-align: center;
    }

    p.characters-text {
        text-align: center;
    }
}

@media screen and (max-width: 500px) {
    .characters-bg {
        width: 50%;
    }
}