.impression {
  width: 100%;
  max-width: 1400px;
  height: 80vh;
  min-height: 550px;
  position: relative;
  display: inline-block;
  text-align: center;
  overflow: visible;
  z-index: 200;
}

.imp-bg {
  width: 30%;
  position: absolute;
  top: 0;
  left: 0;
}

.imp-bg::before {
  content: '';
  display: block;
  padding-top: 160%;
  position: relative;
}

.imp-bg img {
  display: none;
}

.imp-bg-img {
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  background-image: url('../../img/imp-bg.svg');
  background-size: cover;
}

.imp-tslider {
  width: 100%;
  display: block;
  position: absolute;
  top: 115px;
  left: 0;
}

.imp-block {
  width: 100%;
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translate(-50%);
}

h1.imp-title {
  display: block;
  position: relative;
  font-weight: 800;
  font-size: 4rem;
  line-height: 1;
  text-align: center;
  letter-spacing: 0.08em;
}

h1.imp-title br {
  display: none;
}

p.imp-text {
  width: 100%;
  max-width: 250px;
  display: inline-block;
  position: relative;
  margin: 4rem 0 0;
  font-size: .733rem;
  line-height: 1.2;
  text-align: center;
}

.imp-mint-btn {
  position: relative;
  display: inline-block;
  padding: 10px 20px;
  margin-top: 20px;
  border-radius: 22px;
  background-color: #000;
  color: #fff;
  font-size: 1.25rem;
  text-decoration: none;
}

@media screen and (min-width: 1001px) and (max-width: 1285px) {
  h1.imp-title br {
    display: block;
  }
}

@media screen and (max-width: 1000px) {
  .imp-bg {
    width: 50%;
    top: 50%;
    transform: translateY(-50%);
  }
}

@media screen and (max-width: 500px) {
  .imp-bg {
    width: auto;
    height: 100%;
    position: relative;
    display: inline-block;
  }

  .imp-bg img {
    display: block;
    position: relative;
    height: 100%;
  }

  .imp-bg::before {
    content: none;
  }

  .imp-bg-img {
    display: none;
  }

  h1.imp-title {
    font-size: 3.5rem;
  }
}

@media screen and (max-width: 350px) {
  h1.imp-title {
    font-size: 2.5rem;
  }
}