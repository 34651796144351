.mint {
    width: 100%;
    position: relative;
    display: inline-block;
    padding: 160px 1%;
    box-sizing: border-box;
}

.mint-content {
    width: 100%;
    max-width: 1200px;
    position: relative;
    display: inline-block;
}

.mint-bg {
    width: 25%;
    position: absolute;
    top: -60px;
    right: 0;
}

.mint-bg::before {
    content: '';
    display: block;
    position: relative;
    padding-top: 120%;
}

.mint-bg-img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-image: url('../../img/mint-bg.svg');
    background-size: cover;
}

h2.mint-title {
    width: 100%;
    position: relative;
    margin: 0;
    display: inline-block;
    font-size: 2.5rem;
    font-weight: 900;
    line-height: 1.2;
    letter-spacing: -0.02em;
}

.mint-mint-btn {
    position: relative;
    display: inline-block;
    padding: 10px 20px;
    margin-top: 80px;
    border-radius: 22px;
    background-color: #000;
    color: #fff;
    font-size: 1.25rem;
    text-decoration: none;
}

@media screen and (max-width: 500px) {
    .mint-bg {
        width: 50%;
    }
}