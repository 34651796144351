.footer {
    width: 100%;
    position: relative;
    display: inline-block;
}

.footer-content {
    width: 100%;
    display: inline-block;
    position: relative;
    text-align: center;
}

h2.footer-title {
    width: 100%;
    position: relative;
    margin: 0;
    display: inline-block;
    font-size: 2.5rem;
    font-weight: 900;
    line-height: 1.2;
    letter-spacing: -0.02em;
}

.footer-mint-btn {
    position: relative;
    display: inline-block;
    padding: 10px 20px;
    margin-top: 80px;
    border-radius: 22px;
    background-color: #000;
    color: #fff;
    font-size: 1.25rem;
    text-decoration: none;
}

.footer-bottom {
    width: 96%;
    position: relative;
    display: inline-block;
    background-color: #fff;
    padding: 80px 0;
    margin-top: 240px;
}

.back-to-top {
    width: 55px;
    height: 55px;
    position: absolute;
    top: -22.5px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 50%;
    background-color: #DBFF00;
    background-image: url('../../img/arrow-up.svg');
    background-size: auto;
    background-position: center;
    background-repeat: no-repeat;
}

.copyright {
    position: relative;
    display: inline-block;
    margin-top: 18px;
    font-size: 0.733rem;
    line-height: 1.18181818;
}