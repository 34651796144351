.details {
    width: 100%;
    position: relative;
    display: inline-block;
    background-color: #DBFF00;
    padding: 40px 1%;
    box-sizing: border-box;
}

.details-content {
    width: 100%;
    max-width: 280px;
    position: relative;
    display: inline-block;
}

h2.details-title {
    position: relative;
    display: block;
    margin: 0;
    font-weight: 100;
    font-size: 2.5rem;
    line-height: 1.2;
}

p.details-text {
    position: relative;
    display: block;
    margin-top: 18px;
    font-size: 0.733rem;
    line-height: 1.18181818;
    text-align: justify;
}