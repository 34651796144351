.navigation-outer {
    width: 305px;
    height: 0;
    display: block;
    position: fixed;
    top: 0;
    right: 0;
    margin-right: 2%;
    text-align: center;
    padding-top: 2px;
    z-index: 200;
}

.navigation-outer::after {
    content: '';
    width: 100%;
    height: 2px;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #000;
}

.navigation {
    width: 270px;
    height: calc(80vh + 40px);
    min-height: 400px;
    display: inline-block;
    position: relative;
    padding: 0;
    background-color: #fff;
    overflow: hidden;
    transition: .8s;
}

nav ul {
    height: 100%;
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.nav-items {
    width: 125px;
    height: 100%;
    position: relative;
    display: inline-block;
    overflow: scroll;
}

nav.navigation ul li {
    width: 100%;
    display: inline-block;
    font-size: 1.143rem;
    font-weight: 900;
    line-height: 1.215;
    margin-bottom: 1.625rem;
}

nav.navigation ul li:first-of-type {
    margin-top: 1.625rem;
}

nav.navigation ul li.in-site {
    width: 100%;
    display: inline-block;
    text-align: center;
}

span.nav-text {
    display: inline-block;
    color: #000;
    text-align: left;
    margin-left: 10px;
    vertical-align: middle;
}

nav.mobile-navigation {
    display: none;
}

nav.mobile-navigation .nav-opener, nav.mobile-navigation .nav-closer {
    position: fixed;
    top: 42px;
    right: 20px;
    cursor: pointer;
    z-index: 350;
}

nav.mobile-navigation ul {
    width: 98%;
    height: 98%;
    display: block;
    position: fixed;
    left: 1%;
    top: 1%;
    padding: 80px 20px;
    background-color: #fff;
    box-sizing: border-box;
}

.mobile-navigation ul li {
    width: 100%;
    display: inline-block;
    font-size: 1.143rem;
    font-weight: 900;
    line-height: 1.215;
    margin-bottom: 1.625rem;
}

.scroll-to {
    display: inline-block;
    position: relative;
    color: #000;
    cursor: pointer;
    text-decoration: none;
}

.change-lang {
    width: 100%;
    display: block;
    position: absolute;
    bottom: 32px;
    left: 50%;
    transform: translateX(-50%);
    font-size: .733rem;
    font-weight: 100;
    color: #000;
    line-height: 1;
    text-decoration: none;
    cursor: pointer;
    z-index: 100;
    transition: .8s;
}

.change-lang::before {
    content: '';
    width: 11px;
    height: 11px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    margin: -1px 2px 0 0;
    background-image: url('../../../img/lang.svg');
    background-size: cover;
}

.instagram-icon, .twitter-icon, .discord-icon, .opensea-icon {
    vertical-align: middle;
}

.navigation .instagram, .navigation .twitter, .navigation .discord, .navigation .opensea, .mobile-navigation .instagram, .mobile-navigation .twitter, .mobile-navigation .discord, .mobile-navigation .opensea {
    display: block;
    position: relative;
    float: left;
}

@media screen and (max-width: 1000px) {
    .navigation-outer {
        display: none;
    }

    nav.mobile-navigation {
        display: block;
    }

    .change-lang {
        position: relative;
        margin-top: 64px;
    }
}