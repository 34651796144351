.jp h3.phase-title {
    font-weight: 300;
}

@media screen and (max-width: 900px) {
    .jp h3.phase-title {
        font-size: 2rem;
    }
}

@media screen and (max-width: 600px) {
    .jp h3.phase-title {
        font-size: 1.5rem;
    }
}

@media screen and (max-width: 300px) {
    .jp h3.phase-title {
        font-size: 1.25rem;
    }
}