.team {
    width: 100%;
    max-width: 1000px;
    position: relative;
    display: inline-block;
    padding: 0 1%;
    text-align: center;
    box-sizing: border-box;
}

h2.team-title {
    width: 100%;
    position: relative;
    margin: 0;
    display: inline-block;
    font-size: 2.5rem;
    font-weight: 100;
    line-height: 1.2;
}

.team-block {
    width: 60%;
    max-width: 550px;
    position: relative;
    display: inline-block;
    margin-top: 80px;
}

.team-member {
    width: 50%;
    position: relative;
    display: inline-block;
    padding: 0 5%;
    box-sizing: border-box;
}

.team-member-img {
    width: 98%;
    position: relative;
    margin: 0 1%;
    border-bottom: 5px solid #000;
}

.team-member-img img {
    width: 95%;
    position: relative;
    display: inline-block;
    vertical-align: bottom;
}

h4.team-member-name {
    width: 100%;
    position: relative;
    margin: 0;
    display: inline-block;
    font-size: 2.5rem;
    font-weight: 100;
    line-height: 1.2;
}

h5.team-member-position {
    margin: 0;
    position: relative;
    display: inline-block;
    font-size: 0.733rem;
    font-weight: 400;
    line-height: 1.18181818;
}

@media screen and (max-width: 300px) {
    h2.team-title {
        font-size: 1.5rem;
    }

    .team-block {
        width: 100%;
    }

    h4.team-member-account, h5.team-member-position {
        font-size: 1rem;
    }
}

@media screen and (min-width: 301px) and (max-width: 500px) {
    h2.team-title {
        font-size: 2.25rem;
    }

    .team-block {
        width: 100%;
    }
}