.tslider {
    width: 100%;
    position: relative;
    display: inline-block;
    padding: 0;
    overflow: hidden;
}

_:-ms-lang(x)::-ms-backdrop,
.slider {
  display: -ms-grid;
  overflow: hidden;
}

.text-slider {
    display: flex;
    overflow: hidden;
}

.slider-text {
    display: flex;
    list-style: none;
}

.slider-text:first-of-type {
    animation: loop 80s -40s linear infinite;
}

.slider-text:last-of-type {
    animation: loop2 80s -80s linear infinite;
}

.slider-text > span {
    width: 125px;
    font-size: .8125rem;
    line-height: 1;
}

@keyframes loop {
    0% {
      transform: translateX(100%);
    }
    to {
      transform: translateX(-100%);
    }
  }
  
  @keyframes loop2 {
    0% {
      transform: translateX(0);
    }
    to {
      transform: translateX(-200%);
    }
  }

@media screen and (max-width: 300px) {
  .slider-text > span {
    font-size: 1rem;
  }
}