.roadmap {
    width: 100%;
    position: relative;
    display: inline-block;
    background-color: #DBFF00;
    padding: 40px 1%;
    box-sizing: border-box;
}

.roadmap-content {
    width: 100%;
    max-width: 1200px;
    position: relative;
    display: inline-block;
}

h2.roadmap-title {
    font-weight: 100;
    font-size: 2.5rem;
    line-height: 1.2;
}

.timeline {
    width: 100%;
    position: relative;
    display: inline-block;
}

.line {
    width: 50%;
    height: 105%;
    position: absolute;
    left: 1px;
    top: 0;
    border-right: 2px solid #000;
    box-sizing: border-box;
}

.phase {
    width: 100%;
    display: inline-block;
    margin: 60px 0;
}

.phase-left {
    width: 50%;
    display: inline-block;
    vertical-align: middle;
    text-align: right;
}

.phase-right {
    width: 50%;
    display: inline-block;
    vertical-align: middle;
    text-align: left;
}

.phase-details {
    width: 100%;
    max-width: 450px;
    height: 100%;
    max-height: 450px;
    position: relative;
    display: inline-block;
    background-color: #000;
    box-sizing: border-box;
    z-index: 10;
}

.phase-details::before {
    content: '';
    width: 100%;
    display: block;
    position: relative;
    padding-top: 100%;
}

.phase-right .phase-details {
    margin-left: -10%;
}

.phase-left .phase-details {
    margin-right: -10%;
}

.phase-text-area {
    width: 80%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

h3.phase-title {
    position: relative;
    display: block;
    margin: 0;
    font-weight: 100;
    font-size: 2.5rem;
    line-height: 1.2;
    color: #fff;
    text-align: left;
}

p.phase-text {
    position: relative;
    display: block;
    margin-top: 18px;
    font-size: 0.733rem;
    line-height: 1.18181818;
    color: #fff;
    text-align: justify;
}

.roadmap-bg-1 {
    width: 60%;
    position: relative;
    float: left;
}

.roadmap-bg-1::before {
    content: '';
    display: block;
    padding-top: 150%;
    position: relative;
}

.roadmap-bg-img-1 {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-image: url('../../img/roadmap-bg1.svg');
    background-size: cover;
}

.roadmap-bg-2 {
    width: 60%;
    position: relative;
    float: right;
}

.roadmap-bg-2::before {
    content: '';
    display: block;
    padding-top: 150%;
    position: relative;
}

.roadmap-bg-img-2 {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-image: url('../../img/roadmap-bg2.svg');
    background-size: cover;
}

.roadmap-bg-3 {
    width: 60%;
    position: relative;
    float: left;
}

.roadmap-bg-3::before {
    content: '';
    display: block;
    padding-top: 150%;
    position: relative;
}

.roadmap-bg-img-3 {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-image: url('../../img/roadmap-bg3.svg');
    background-size: cover;
}

.roadmap-bg-4 {
    width: 60%;
    position: relative;
    float: right;
}

.roadmap-bg-4::before {
    content: '';
    display: block;
    padding-top: 150%;
    position: relative;
}

.roadmap-bg-img-4 {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-image: url('../../img/roadmap-bg4.svg');
    background-size: cover;
}

.current {
    width: 20%;
    display: block;
    position: absolute;
    top: -2px;
    left: 5%;
    border: 2px solid #000;
    background-color: #fff;
}

.current::before {
    content: '';
    display: block;
    position: relative;
    padding-top: 120%;
}

p.current-text {
    width: 100%;
    display: block;
    position: absolute;
    bottom: 0;
    font-size: .733rem;
    font-style: italic;
    line-height: 1.18181818;
    text-align: center;
}

@media screen and (max-width: 350px) {
    h3.phase-title {
        font-size: 1.25rem;
    } 
}

@media screen and (min-width: 501px) and (max-width: 600px) {
    h3.phase-title {
        font-size: 1.25rem;
    }
}

@media screen and (max-width: 500px) {
    .phase-right {
        width: 90%;
        float: right;
    }

    .phase-left {
        width: 90%;
        float: left;
    }

    .phase-left .phase-details, .phase-right .phase-details {
        margin: 0;
    }

    .roadmap-bg-2, .roadmap-bg-3 {
        display: none;
    }

    .roadmap-bg-1 {
        width: 40%;
        margin-bottom: -10%;
    }

    .roadmap-bg-4 {
        width: 40%;
        margin-top: -10%;
    }
}
