.faqs {
  width: 100%;
  position: relative;
  display: inline-block;
  padding: 120px 1%;
  text-align: center;
  box-sizing: border-box;
}

.faqs-content {
  width: 100%;
  max-width: 1200px;
  position: relative;
  display: inline-block;
  box-sizing: border-box;
}

.faqs-bg {
  width: 25%;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.faqs-bg::before {
  content: '';
  display: block;
  padding-top: 100%;
  position: relative;
}

.faqs-bg-img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-image: url('../../img/faqs-bg.svg');
  background-size: cover;
}

h2.faqs-title {
  width: 100%;
  position: relative;
  margin: 0;
  display: inline-block;
  font-size: 2.5rem;
  font-weight: 100;
  line-height: 1.2;
}

.faqs-block {
  width: 100%;
  max-width: 400px;
  position: relative;
  display: inline-block;
  margin-top: 40px;
}

.faq {
  display: block;
  margin-bottom: 40px;
  box-sizing: border-box;
}

.faq::after {
  content: '';
  display: block;
  position: relative;
  width: 100%;
  height: 17px;
  margin-top: 10px;
}

.faq-q {
  width: 100%;
  position: relative;
  cursor: pointer;
  display: block;
  border: none;
  outline: none;
  background: none;
}

.faq-q-text {
  max-width: calc(100% - 40px);
  display: block;
  position: relative;
  float: left;
  text-align: left;
  margin: 0;
  font-size: 0.733rem;
  font-weight: 400;
  line-height: 1.18181818;
  color: #000;
}

.faq-icon {
  width: 17px;
  height: 17px;
  display: block;
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  margin-left: auto;
  transition: transform 0.6s ease;
}

.faq-icon::after {
  content: "";
  width: 100%;
  height: 100%;
  display: block;
  background-image: url('../../img/plus.svg');
  background-size: cover;
}

.open.faq-icon::after {
  content: "";
  background-image: url('../../img/minus.svg');
}

.faq-a {
  text-align: justify;
  overflow: auto;
  transition: max-height 0.6s ease;
}

.faq-a-text {
  font-size: 0.733rem;
  font-weight: 400;
  line-height: 1.18181818;
  color: #000;
  padding: 20px 10px;
  box-sizing: border-box;
}

@media screen and (max-width: 767px) {
  .faqs-bg {
    display: none;
  }
}